import moment from 'moment';
import IS from '@tools/Utils/IS';
import { OBJ } from 'ahq-front-tools';
import { createReducer } from '@reduxjs/toolkit';
import { sortByDate } from '../../Utils/Date';
import { removeBrowserCache } from '../../Utils/Cache';
import { clearLocalStorage, clearClassData, setDisconnected } from '../actions/LocalCacheActions';
import { setLiveUpdateStatus, setLoadings, setLocalCache, setTotalCount } from '../actions/LocalCacheActions';

const initState: InitStateType = { loadings: { init: true }, disconnected: false };

const LocalCacheReducer = createReducer(initState, {
	//* Live update status
	[setLiveUpdateStatus.type]: (state, { payload }) => ({ ...state, liveUpdateStatus: payload }),

	//* Set server is disconnected
	[setDisconnected.type]: (state, { payload }) => ({ ...state, disconnected: payload }),

	//* Set initial and refresh cache loadings
	[setLoadings.type]: (state, { payload }) => ({
		...(state || {}),
		loadings: { ...state?.loadings, ...(payload || {}) },
	}),

	//* Clear all classes cached data
	[clearLocalStorage.type]: (state, { payload }) => {
		const { init_loading, refresh_loading } = payload;
		return {
			...initState,
			disconnected: state?.disconnected,
			liveUpdateStatus: state?.liveUpdateStatus,
			loadings: { init: init_loading, refresh: refresh_loading },
		};
	},

	//* Clear cached data for specified class
	[clearClassData.type]: (state, { payload }) => {
		removeBrowserCache();
		const new_state = OBJ.clone(state);
		delete (new_state as any)?.[payload];
		return new_state as InitStateType;
	},

	//*---------------------- Class Data reducers -------------------------

	[setLocalCache.type]: (state: InitStateType, { payload }) => {
		let { data = [], deleted = [], src } = payload;
		let { class_name, partialUpdate = !!deleted?.length, last_update } = payload;

		//? Don't update the state if there is no update.
		if (!data?.length && !deleted?.length) return { ...state };

		const PRIMARY_KEY = `${class_name}_ID`;
		if (!partialUpdate) data = (data as any[])?.reduce((acc, i) => (acc[i?.[PRIMARY_KEY]] = i) && acc, {});
		else data = dataUpdater((state as any)?.[class_name] || {}, data, deleted, PRIMARY_KEY);

		//* New State
		const newState = { ...(state || {}), [class_name]: data };

		//? Only update the last_update date if the source is backend
		if (src === 'backend') {
			const now = last_update || moment().utc().toISOString();
			newState.last_update = { ...(newState?.last_update || {}), [class_name]: now };
		}

		return newState;
	},

	[setTotalCount.type]: (state: InitStateType, { payload }) => {
		const { className, count, no_caching } = payload || {};
		return {
			...(state || {}),
			total_count: {
				...(state?.total_count || {}),
				[className]: count,
			},
			no_caching_classes: {
				...(state?.no_caching_classes || {}),
				[className]: !!no_caching,
			},
		};
	},

	//*----------------------------------------------------------------------
});

const dataUpdater = (cur_data: Record<string, any>, partial_data: any[], deleted_ids: string[], pk: string) => {
	let data: any = { ...cur_data };
	if (IS.array(deleted_ids)) for (const di of deleted_ids || []) if (!!di) delete data?.[di];
	if (IS.array(partial_data)) for (const pd of partial_data || []) if (!!pd?.[pk]) data[pd?.[pk]] = pd;

	//? ORDER_VIEW Limit
	const ORDER_VIEW_LIMIT = 200;
	if (pk === 'ORDER_VIEW_ID') {
		const length = Object.keys(data || {}).length;
		if (length > ORDER_VIEW_LIMIT) {
			data = Object.values(data).sort((a: any, b: any) => -sortByDate(a?.date, b?.date));
			data = data?.slice(0, ORDER_VIEW_LIMIT);
			data = data?.reduce((acc: any, i: any) => (acc[i?.[pk]] = i) && acc, {} as any);
		}
	}

	return data;
};

type InitStateType = EXTRA & CLASS_DATA_TYPE;

type CLASS_DATA_TYPE = Partial<Record<CLASS_NAMES, Record<string, any>>>;

type EXTRA = {
	disconnected?: boolean;
	liveUpdateStatus?: boolean;
	loadings?: { init?: boolean; refresh?: boolean };
	last_update?: Partial<Record<SUG<CLASS_NAMES>, string>>;
	total_count?: Partial<Record<SUG<CLASS_NAMES>, number>>;
	no_caching_classes?: Partial<Record<SUG<CLASS_NAMES>, boolean>>;
};

export default LocalCacheReducer;
